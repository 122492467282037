let dollar;

export const getDollar = async () => {
  const resp = await fetch('https://dolarapi.com/v1/dolares/blue');
  const json = await resp.json();
  dollar = json.compra;// (json.compra + json.venta) / 2;
};

const fetchWithCache = async (url, parser, cache) => {
  if (cache && sessionStorage.getItem('cache-' + url)) {
    return JSON.parse(sessionStorage.getItem('cache-' + url));
  }
  const resp = await fetch(url);
  const html = await resp.text();
  const parsed = await parser(html);
  if (cache) {
    sessionStorage.setItem('cache-' + url, JSON.stringify(parsed));
  }
  return parsed;
};

export const getMarcas = async (segmento) => {
  const marcas = await fetchWithCache('https://www.deruedas.com.ar/busCraw.asp?segmento=' + segmento + '&provincia=1&weNeed=divAll&pag=1&_=1696511893294', (html) => {
    const links = $(html).find("#divModelosFancy a").toArray();
    const result = links.map((link) => {
      return [link.getAttribute('refreshValue'), link.innerText];
    });

    return result;
  });
  return marcas;
}

export const getModelos = async (segmento, marca) => {
  const modelos = await fetchWithCache('https://www.deruedas.com.ar/busCraw.asp?segmento=' + segmento + '&marca=' + marca + '&weNeed=divAll&pag=1&_=1696513602082', (html) => {
    const links = $(html).find("#divModelosFancy a").toArray();
    const result = links.map((link) => {
      return [link.getAttribute('refreshValue'), link.innerText];
    });

    return result;
  });
  return modelos;
}

const getVersiones = async (segmento, marca, modelo, anio) => {
  const versiones = await fetchWithCache('https://www.deruedas.com.ar/busCraw.asp?segmento=' + segmento + '&marca=' + marca + '&modelo=' + modelo + '&anio_1=' + (anio - 2) + '&anio_2=' + (anio + 2) + '&weNeed=divAll&pag=1&_=1696513602082', (html) => {
    const links = $(html).find("#divModelosFancy a").toArray();
    const result = links.map((link) => {
      return [link.getAttribute('refreshValue'), link.innerText];
    });

    return result;
  });
  return versiones;
}

const getPublicaciones = async (segmento, marca, modelo, version, page = 1, cache) => {
  const result = await fetchWithCache('https://www.deruedas.com.ar/busCraw.asp?segmento=' + segmento + '&marca=' + marca + '&modelo=' + modelo + '&weNeed=divAll&pag=' + page + '&_=1696513602082', (html) => {
    const divs = $(html).find(".divCar_2.resaltar,.divCar_2.off").toArray();
    return divs.map((div) => {
      const addText = $(div).find('.texto:eq(0)').text().trim();
      let precio = $(div).find('.titulo:eq(1)').text().replace(/\./g, '');
      if (precio.indexOf('U$ ') > -1) {
        // todo: handle usd
        precio = Math.round(Number(precio.substr(2)) * dollar);
      } else {
        precio = Number(precio.substr(1));
      }
      return [$(div).find('h2').text(), $(div).find('h2').next().next().text(), precio, addText.split('|')[0].trim(), Number(addText.split('|')[1].trim()), $(div).find('h2').parent().parent().attr('href')];
    });
  }, cache);

  const words = version.toLowerCase().split(' ').filter(w => w);
  const includeWords = words.filter(w => w[0] !== '-');
  const excludeWords = [...words.filter(w => w[0] === '-').map(w => w.substr(1)), ...(words.includes('at') ? [] : ['at', 'a/t'])];

  const filteredResults = result.filter((([_, v]) => {
    const expectedWorkds = v.toLowerCase().split(' ');
    if (includeWords.length === 0) {
      return true;
    }
    const yes = includeWords.every(w => {
      return expectedWorkds.includes(w);
    });
    const no = excludeWords.every(w => {
      return !expectedWorkds.includes(w);
    });
    
    return yes && no;
  }));
  
  if (result.length > 0) {
    filteredResults.push(...await getPublicaciones(segmento, marca, modelo, version, page + 1, cache));
  }
  
  return filteredResults;
}

const findMostSuitablePrice = (combustible, anio, precios) => {
  let result = [];
  let flags = [];

  // first same combustible and anio
  if (result.length === 0) {
    result = precios.filter(([_, __, p, c, a]) => c === combustible && a === anio);
  }

  // then diff combustible and same anio
  if (result.length === 0) {
    flags = [combustible];
    result = precios.filter(([_, __, p, c, a]) => a === anio);
  }

  // then same combustible and closest anio
  if (result.length === 0) {
    const temp = precios.filter(([_, __, p, c, a]) => c === combustible);
    let closestAnio = temp[0][4];
    temp.forEach(p => {
      if (Math.abs(p[4] - anio) < Math.abs(closestAnio - anio)) {
        closestAnio = p[4];
      }
    });
    flags = [closestAnio];
    result = temp.filter(([_, __, p, c, a]) => a === closestAnio);
  }

  // then diff combustible and closest anio
  if (result.length === 0) {
    let closestAnio = result[0][4];
    result.forEach(p => {
      if (Math.abs(p[4] - anio) < Math.abs(closestAnio - anio)) {
        closestAnio = p[4];
      }
    });
    flags = [combustible, closestAnio];
    result = result.filter(([_, __, p, c, a]) => a === closestAnio);
  }

  return {
    min: Math.min(...result.map(r => r[2])),
    max: Math.max(...result.map(r => r[2])),
    avg: Math.round(result.map(r => r[2]).reduce((a, b) => a + b, 0) / result.length),
    flags,
    refs: result,
  };
};

export const evaluateAsset = async (car, cache = true) => {
  const [segmento, marca, modelo, version, combustible, anio] = car;
  try {
    const precios = await getPublicaciones(segmento, marca, modelo, version, 1, cache);

    const precio = findMostSuitablePrice(combustible, anio, precios);
    return { segmento, marca, modelo, version, anio, precios, precio };
  } catch (e) {
    console.error(e);
    return { segmento, marca, modelo, version, anio, precios: [], precio: null };
  }
};
