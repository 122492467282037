import { useEffect, useState } from 'react';
import './App.css';
import { evaluateAsset, getMarcas, getModelos } from './lib';
import { formatNumber } from './utils';

function Search({ onAdd, onClose, initialValues }) {
  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState(null);

  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);

  const [segment, setSegment] = useState(initialValues ? initialValues[0] : 0);
  const [make, setMake] = useState(initialValues ? initialValues[1] : '');
  const [model, setModel] = useState(initialValues ? initialValues[2] : '');
  const [version, setVersion] = useState(initialValues ? initialValues[3] : '');
  const [year, setYear] = useState(initialValues ? initialValues[5] : 2010);
  const [fuel, setFuel] = useState(initialValues ? initialValues[4] : 'Nafta');

  useEffect(() => {
    getMarcas(segment).then(res => {
      setMakes(res);
      setModels([]);
    });
  }, [segment]);

  useEffect(() => {
    setModels([]);
    setResults(null);
    if (make) {
      getModelos(segment, make).then(res => {
        setModels(res);
      });
    }
  }, [segment, make]);

  const handleSearch = () => {
    setSearching(true);
    evaluateAsset([segment, make, model, version, fuel, year], false).then(res => {
      setResults(res);
      setSearching(false);
    });
  };

  const handleSegmentChange = (e) => {
    setSegment(Number(e.target.value));
  };

  const handleMakeChange = (e) => {
    setMake(e.target.value);
  };

  const handleModelChange = (e) => {
    setModel(e.target.value);
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  const handleFuelChange = (e) => {
    setFuel(e.target.value);
  };

  return (
    <div className="fixed top-0 left-0 bg-white p-2 w-full h-screen overflow-y-auto">
      <h2>Buscar Auto</h2>
      <div>Segmento</div>
      <select onChange={handleSegmentChange} value={segment}>
        <option value="0">Autos</option>
        <option value="1">Camionetas</option>
        <option value="2">Motos</option>
      </select>

      <br />

      <div>Marca</div>
      <select onChange={handleMakeChange} value={make} disabled={initialValues}>
        <option value="">Seleccionar</option>
        {initialValues ? (
          <option value={initialValues[1]}>{initialValues[1]}</option>
        ) : (
          makes.map(([id, name]) => (
            <option key={id} value={id}>{name}</option>
          ))
        )}
      </select>

      <br />

      <div>Modelo</div>
      <select onChange={handleModelChange} value={model} disabled={initialValues}>
        <option value="">Seleccionar</option>
        {initialValues ? (
          <option value={initialValues[2]}>{initialValues[2]}</option>
        ) : (
          models.map(([id, name]) => (
            <option key={id} value={id}>{name}</option>
          ))
        )}
      </select>

      <br />

      <div>Version</div>
      <input placeholder="Escribir Version" value={version} onChange={(e) => setVersion(e.target.value)} />

      <br />

      <div>Año</div>
      <select onChange={handleYearChange} value={year}>
        <option value="">Seleccionar</option>
        {new Array(25).fill(null).map((_, index) => (
          <option key={index + 2000} value={index + 2000}>{index + 2000}</option>
        ))}
      </select>

      <br />

      <div>Combustible</div>
      <select onChange={handleFuelChange} value={fuel}>
        <option value="Nafta">Nafta</option>
        <option value="GNC">GNC</option>
        <option value="Diesel">Diesel</option>
      </select>

      <button disabled={searching} onClick={handleSearch}>{searching ? 'Buscando...' : 'Buscar'}</button><br />

      {!searching && results && (
        <div>
          <div>Resultados</div>
          {results.precio ? (
            <div>
              <div>Precio Minimo: {formatNumber(results.precio.min)}</div>
              <div>Precio Promedio: {formatNumber(results.precio.avg)}</div>
              <div>Precio Maximo: {formatNumber(results.precio.max)}</div>
              <div>Observaciones: {results.precio.flags.join(' ')}</div>
              <div className='table-wrapper'>
                <table>
                  <tbody>
                    {results.precio.refs.map((ref, i) => (
                      <tr key={i}>
                        <td colSpan={2}>{`${ref[1]} ${ref[3]} ${ref[4]}`}</td>
                        <td colSpan={2}>{formatNumber(ref[2])}</td>
                        <td colSpan={2}><a href={'https://www.deruedas.com.ar/' + ref[5]} target='_blank'>Link</a></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div>No hay resultados</div>
          )}
          <button onClick={() => onAdd([segment, make, model, version, fuel, year])}>{initialValues ? 'Editar este auto' : 'Agregar este auto al capital'}</button><br />
        </div>
      )}

      <button onClick={() => onClose()}>Volver</button>
    </div>
  );
}

export default Search;
