let base = 'https://capital-api.francoromera.app';

if (process.env.NODE_ENV === 'development2') {
  base = 'http://localhost:3002';
}

export const getCars = async () => {
  const resp = await fetch(base + '/api/v1/car');
  const json = await resp.json();
  return json;
};

export const getData = async () => {
  const resp = await fetch(base + '/api/v1/data');
  const json = await resp.json();
  return json;
};

export const createCar = async (car) => {
  const resp = await fetch(base + '/api/v1/car', {
    method: 'POST',
    body: JSON.stringify(car),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const json = await resp.json();
  return json;
};

export const deleteCar = async (carId) => {
  await fetch(base + '/api/v1/car/' + carId, {
    method: 'DELETE',
  });
  return true;
};

export const updateData = async (value) => {
  const resp = await fetch(base + '/api/v1/data', {
    method: 'POST',
    body: JSON.stringify(value),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const json = await resp.json();
  return json;
};
