import './App.css';
import { useEffect, useState } from 'react';
import { getDollar } from './lib';
import Data from './Data';
import { getCars, getData } from './api';

function App() {
  const [loading, setLoading] = useState(true);
  const [capital, setCapital] = useState(null);
  const [data, setData] = useState({});
  
  useEffect(() => {
    Promise.all([
      getDollar(),
      getCars(),
      getData(),
    ]).then(([
      dollar,
      cars,
      data,
    ]) => {
      setLoading(false);
      setCapital(cars.map((car) => [car.type, car.make, car.model, car.version, car.fuel, car.year, car._id]));
      setData(data);
    });
  }, []);

  if (loading) {
    return "Cargando...";
  }

  const handleChange = () => {
    setLoading(true);
    getCars().then((cars) => {
      setLoading(false);
      setCapital(cars.map((car) => [car.type, car.make, car.model, car.version, car.fuel, car.year, car._id]));
    });
  }
  
  return (
    <Data capital={capital} data={data} onChange={handleChange} />
  );
}

export default App;
