import './App.css';
import { useEffect, useState } from 'react';
import { evaluateAsset } from './lib';
import Search from './Search';
import { buildAssetName, buildResultName, cleanUpNumber, formatNumber, formatVersion } from './utils';
import { createCar, deleteCar, updateData } from './api';

const originalAssets = [
  [0, 'Ford', 'Focus', 'III 2.0 SE 5ptas -Plus -Powershift', 'Nafta', 2015],
  [1, 'Fiat', 'Fiorino', '1.4 EVO', 'GNC', 2015],
  [0, 'Suzuki', 'Fun', '1.4 5ptas', 'Nafta', 2009],
  [0, 'Ford', 'Fiesta KD', '1.6 S', 'Nafta', 2014],
  [1, 'Chevrolet', 'S10', '2.8 4x2 LS', 'Diesel', 2017],
  [0, 'Renault', 'Duster', '1.6 4x2 Dynamique', 'Nafta', 2017],
  [0, 'Ford', 'Kuga', '2.5 Trend', 'Nafta', 2011],
]

function Data({ capital, onChange, data: savedData }) {
  const [showSearch, setShowSearch] = useState(false);
  const [results, setResults] = useState(originalAssets.map(() => null));
  const [results2, setResults2] = useState(capital.map(() => null));
  const [expanded, setExpanded] = useState(-1);
  const [expanded2, setExpanded2] = useState(-1);
  const [data, setData] = useState(savedData || 0);
  const [editing, setEditing] = useState(null);

  useEffect(() => {
    originalAssets.forEach((car, index) => {
      evaluateAsset(car).then((res) => {
        setResults((prev) => {
          const newValue = [...prev];
          newValue[index] = res;
          return newValue;
        });
      });
    });
  }, []);

  useEffect(() => {
    capital.forEach((car, index) => {
      evaluateAsset(car).then((res) => {
        setResults2((prev) => {
          const newValue = [...prev];
          newValue[index] = res;
          return newValue;
        });
      });
    });
  }, [capital]);

  const readyOriginal = results.every(a => !!a);
  const readyActual = results2.every(a => !!a);

  const totalOriginalMin = readyOriginal ? results.reduce((acc, result) => {
    return acc + (result.precio?.min || 0);
  }, 0) : '...';
  const totalOriginalMax = readyOriginal ? results.reduce((acc, result) => {
    return acc + (result.precio?.max || 0);
  }, 0) : '...';
  const totalOriginalAvg = readyOriginal ? results.reduce((acc, result) => {
    return acc + (result.precio?.avg || 0);
  }, 0) : '...';
  const totalOriginalAdj = readyOriginal ? results.reduce((acc, result) => {
    return acc + (Number(data[buildResultName(result)] *1000) || result.precio?.min || 0);
  }, 0) : '...';
  
  const totalActualMin = readyActual ? results2.reduce((acc, result) => {
    return acc + (result.precio?.min || 0);
  }, 0) : '...';
  const totalActualMax = readyActual ? results2.reduce((acc, result) => {
    return acc + (result.precio?.max || 0);
  }, 0) : '...';
  const totalActualAvg = readyActual ? results2.reduce((acc, result) => {
    return acc + (result.precio?.avg || 0);
  }, 0) : '...';
  const totalActualAdj = readyActual ? results2.reduce((acc, result) => {
    return acc + (Number(data[buildResultName(result)] * 1000) || result.precio?.min || 0);
  }, 0) : '...';

  const handleDelete = (id) => {
    deleteCar(id).then(() => {
      onChange();
    });
  };

  const handleEdit = (asset) => {
    setEditing(asset);
    setShowSearch(true);
  };

  const handleCaja = (e) => {
    setData({
      ...data,
      caja: cleanUpNumber(e.target.value),
    });
  }

  const handlePriceBlur = () => {
    updateData(data);
  }

  const handleCajaBlur = () => {
    updateData(data);
  }

  const renderCapital = (assets, baseResults, expanded, setExpanded) => (
    <div className='table-wrapper'>
      <table>
        <thead>
          <tr>
            <th>Auto</th>
            <th>$ Minimo</th>
            <th>$ Promedio</th>
            <th>$ Maximo</th>
            <th>$ Ajustado</th>
            <th>Obs</th>
            <th>Refs</th>
          </tr>
        </thead>
        <tbody>
          {assets.sort((a, b) => buildAssetName(a).localeCompare(buildAssetName(b))).map((a, index) => {
            const name = buildAssetName(a);
            return (
              <>
                <tr key={name} className='bg-gray-300'>
                  <td>
                    {name}
                    {a[6] ? (
                      <button className='bg-red-300 py-0 ml-2' onClick={() => window.confirm('Borrar?') ? handleDelete(a[6]) : null}>Borrar</button>
                    ) : null}
                    {a[6] ? (
                      <button className='bg-blue-300 py-0 ml-2' onClick={() => handleEdit(a)}>Editar</button>
                    ) : null}
                  </td>
                  <td>{baseResults[index]?.precio ? formatNumber(baseResults[index].precio.min) : '...'}</td>
                  <td>{baseResults[index]?.precio ? formatNumber(baseResults[index].precio.avg) : '...'}</td>
                  <td>{baseResults[index]?.precio ? formatNumber(baseResults[index].precio.max) : '...'}</td>
                  <td><input type='number' onBlur={handlePriceBlur} value={data[name]} onChange={(e) => setData({ ...data, [name]: cleanUpNumber(e.target.value) })} step={100} /></td>
                  <td>{baseResults[index]?.precio ? baseResults[index].precio.flags.join(' ') : '...'}</td>
                  <td>{baseResults[index]?.precio ? (
                    <button onClick={() => setExpanded(expanded === index ? -1 : index)}>Ver ({baseResults[index].precio.refs.length})</button>
                  ) : '...'}</td>
                </tr>
                {baseResults[index]?.precio ? baseResults[index].precio.refs.map((ref, i) => (
                  <tr key={i} style={{ display: expanded === index ? 'table-row' : 'none' }}>
                    <td colSpan={2}>{`${a[1]} ${a[2]} ${ref[1]} ${formatVersion(ref[3])} ${ref[4]}`}</td>
                    <td colSpan={3}>{formatNumber(ref[2])}</td>
                    <td colSpan={2}><a href={'https://www.deruedas.com.ar/' + ref[5]} target='_blank'>Link</a></td>
                  </tr>
                )) : null}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  const handleAdd = ([segment, make, model, version, fuel, year]) => {
    createCar({
      make,
      model,
      version,
      year: Number(year),
      fuel,
      type: segment,
    }).then(() => {
      if (editing) {
        return deleteCar(editing[6]);
      }  
    }).then(() => {
      onChange();
      setEditing(null);
      setShowSearch(false);
    });
  }

  const cajaValue = !isNaN(data?.caja) ? Number(data.caja) : 0;

  return (
    <div>
      <h2>Resumen:</h2>
      <div className='table-wrapper'>
        <table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Minimo</th>
              <th>Promedio</th>
              <th>Maximo</th>
              <th>Ajustado</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Capital Original</td>
              <td>{Number(totalOriginalMin).toLocaleString()}</td>
              <td>{Number(totalOriginalAvg).toLocaleString()}</td>
              <td>{Number(totalOriginalMax).toLocaleString()}</td>
              <td>{Number(totalOriginalAdj).toLocaleString()}</td>
            </tr>
            <tr>
              <td>Capital Actual</td>
              <td>{Number(totalActualMin).toLocaleString()}</td>
              <td>{Number(totalActualAvg).toLocaleString()}</td>
              <td>{Number(totalActualMax).toLocaleString()}</td>
              <td>{Number(totalActualAdj).toLocaleString()}</td>
            </tr>
            <tr>
              <td>Caja</td>
              <td>{cajaValue}</td>
              <td>{cajaValue}</td>
              <td>{cajaValue}</td>
              <td>{cajaValue}</td>
            </tr>
            <tr>
              <td>Diferencia</td>
              <td>{Number(totalActualMin - totalOriginalMin + cajaValue).toLocaleString()}</td>
              <td>{Number(totalActualAvg - totalOriginalAvg + cajaValue).toLocaleString()}</td>
              <td>{Number(totalActualMax - totalOriginalMax + cajaValue).toLocaleString()}</td>
              <td>{Number(totalActualAdj - totalOriginalAdj + cajaValue).toLocaleString()}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <h2>Caja:</h2>
      <input type='number' value={data?.caja || 0} onChange={handleCaja} onBlur={handleCajaBlur} step={100} />
      <h2>Capital Actual:</h2>
      {renderCapital(capital, results2, expanded2, setExpanded2)}
      <button onClick={() => setShowSearch(!showSearch)}>
        Buscar Auto
      </button>
      <br />
      <h2>Capital Original:</h2>
      {renderCapital(originalAssets, results, expanded, setExpanded)}
      {showSearch && <Search initialValues={editing} onClose={() => {
        setShowSearch(false);
        setEditing(false);
      }} onAdd={handleAdd} />}
    </div>
  );
}

export default Data;
