export const formatNumber = (value) => {
  return Math.round(Number(value) / 1000).toLocaleString() + ' M';
}

export const formatVersion = (version) => {
  return version.split(' ').filter(w => w && w[0] !== '-').join(' ');
}

export const cleanUpNumber = (value) => {
  return value ? String(value).replaceAll(/[^0-9]/g, '') : '';
}

export const buildAssetName = (asset) => {
  return `${asset[1]} ${asset[2]} ${formatVersion(asset[3])} ${asset[5]}`;
}

export const buildResultName = (result) => {
  return `${result.marca} ${result.modelo} ${formatVersion(result.version)} ${result.anio}`;
}
